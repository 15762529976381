const doorSlider = {
  init () {
    $(".c-door-slider").slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        speed: 500,
		fade: false,
		autoplay: true,
		centerMode: false,
		arrows: true,
		dots: false,
        nextArrow: '<button class="c-card-slider-nav-next"> <span class="icon-right"></span> </button>',
        prevArrow: '<button class="c-card-slider-nav-prev"> <span class="icon-left"></span> </button>',
        infinite: true,
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 5,
		        slidesToScroll: 5,
		        infinite: true,
		        dots: true
		      }
		    },
		    {
		      breakpoint: 850,
		      settings: {
		        slidesToShow: 4,
		        slidesToScroll: 4
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    }
		   ]
      });
  	}
  };

  export default doorSlider;