const cardSlider = {
  init () {
    $(".c-card-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 500,
		fade: false,
		autoplay: true,
		centerMode: false,
		arrows: true,
		dots: false,
        nextArrow: '<button class="c-card-slider-nav-next"> <span class="icon-right"></span> </button>',
        prevArrow: '<button class="c-card-slider-nav-prev"> <span class="icon-left"></span> </button>',
        infinite: true,
        responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3,
		        infinite: true,
		        dots: true
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		   ]
      });
  	}
  };

  export default cardSlider;

/*

const cardSlider = {
  init () {
   $('.c-card-slider').owlCarousel({
	    loop:true,
	    margin:10,
	    nav:true,
	    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:3
		        },
		        1000:{
		            items:5
		        }
		    }
		});
	  }
  };

  export default cardSlider;
*/