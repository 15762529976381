const testSlider = {
  init () {
    $(".c-testimonial-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
		fade: true,
		autoplay: true,
		arrows: false,
		dots: false,
		cssEase: 'linear',
        nextArrow: '<button class="c-testimonial-slider-nav-next"> <i class="nav-arrow--next"></i> </button>',
        prevArrow: '<button class="c-testimonial-slider-nav-prev"> <i class="nav-arrow--prev"></i> </button>',
        infinite: true 
      });
  	}
  };

  export default testSlider;
