const productSlider = {
  init () {
    $(".c-product-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
		fade: false,
		autoplay: true,
		centerMode: false,
		arrows: false,
		dots: true,
        nextArrow: '<button class="c-card-slider-nav-next"> <span class="icon-right"></span> </button>',
        prevArrow: '<button class="c-card-slider-nav-prev"> <span class="icon-left"></span> </button>',
        infinite: true
      });
  	}
  };

  export default productSlider;