// NPM dependencies
import $ from 'jquery';
import 'babel-polyfill';
import 'foundation-sites';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import 'lightgallery';


// Project Partials
// import Object from '../../src/components/03-ui/component-folder/component-file-name';
import mainNav from '../../src/components/03-ui/site-header/site-header';
import testSlider from '../../src/components/03-ui/testimonials-slider/testimonials-slider';
import cardSlider from '../../src/components/03-ui/card-slider/card-slider';
import productSlider from '../../src/components/03-ui/product-slider/product-slider';
import detailGallery from '../../src/components/03-ui/detail-gallery/detail-gallery';
import doorSlider from '../../src/components/03-ui/door_slider/door_slider';

$(document).ready(() => {
	// Init foundation on doc ready
	$(document).foundation();

  // Object Fit Polyfill
  // https://github.com/bfred-it/object-fit-images
  objectFitImages();

	// Initialise imported js (this would be js required for every page, globally, i.e. headers
	// Object.init();
	mainNav.init();
	testSlider.init();
	cardSlider.init();
	productSlider.init();
	detailGallery.init();
	doorSlider.init();

	// Conditionally import and initialise additional chunks
	// See @ https://webpack.js.org/guides/code-splitting/ && https://sebastiandedeyne.com/posts/2018/code-splitting-with-laravel-mix
	// Depending on the page, these additional components may or may not be needed.
});
